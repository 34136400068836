<template>
  <div id="div">
    <el-card
      class="box-card"
      style="
        padding: 0 10 10 10;
        width: 18.75rem;
        border-radius: 0.625rem;
        background-color: #ebeff5;
      "
    >
      <div id="del-div">
        <el-link
          type="primary"
          style="color: #000000"
          icon="el-icon-close"
          @click="close()"
        ></el-link>
      </div>
      <div slot="header">
        <h4>{{ infocompany }}</h4>
      </div>
      <div>
        <table class="info_mess" border="1">
          <colgroup>
            <col width="70" text-align="end" />
            <col width="auto" text-align="start" />
          </colgroup>
          <tr>
            <td class="mess_left" text-align="end">安放位置:</td>
            <td class="mess_right" colspan="3">
              {{ infoplace }}
            </td>
          </tr>
          <tr>
            <td class="mess_left">设备编号:</td>
            <td class="mess_right" colspan="3">{{ infocode }}</td>
          </tr>
          <tr v-if="v_id !== 1 && v_id !== 3">
            <td class="mess_left">工作时长:</td>
            <td class="mess_right" colspan="3">{{ infotime_length }}</td>
          </tr>
          <tr>
            <td class="mess_left">报警次数:</td>
            <td class="mess_right" colspan="3">{{ infoamount }}</td>
          </tr>
          <tr>
            <td class="mess_left">设备型号:</td>
            <td class="mess_right" colspan="3">{{ infoversion }}</td>
          </tr>
          <tr>
            <td class="mess_left">路面宽度:</td>
            <td class="mess_right">{{ infosupervisewidth }}</td>
          </tr>
          <tr>
            <td class="mess_left" v-if="v_id !== 3">剩余电量:</td>
            <td class="mess_right right_bottom" v-if="v_id !== 3">
              {{ infopower }}%
            </td>

            <td class="mess_left left_bottom">道路编号:</td>
            <td class="mess_right right_bottom">{{ roadnum }}</td>
          </tr>
          <tr>
            <td class="mess_left">近报警速度:</td>
            <td class="mess_right right_bottom">{{ infocloseapeed }}</td>

            <td class="mess_left left_bottom">里程桩号:</td>
            <td class="mess_right right_bottom">{{ mileagenum }}</td>
          </tr>
          <tr>
            <td class="mess_left">近报警距离:</td>
            <td class="mess_right right_bottom">{{ infocloserange }}</td>
            <td class="mess_left left_bottom">道路去向:</td>
            <td class="mess_right right_bottom">{{ roadtogo }}</td>
          </tr>
          <tr>
            <td class="mess_left">远报警速度:</td>
            <td class="mess_right right_bottom">{{ inforemotespeed }}</td>
            <td class="mess_left left_bottom">施工内容：</td>
            <td class="mess_right right_bottom">
              {{ drivingto }}
            </td>
          </tr>
        </table>
        <div id="infoWindowTool" v-if="v_id !== 1">
          <el-button
            type="primary"
            style="backgroundcolor: #0076f6"
            size="small"
            v-if="have_video == 1 && v_id !== 3"
            @click="playVideo()"
            >前置监控</el-button
          >
          <el-button
            type="primary"
            style="backgroundcolor: #0076f6"
            size="small"
            v-else-if="v_id == 3"
            @click="playVideo()"
            >视频监控</el-button
          >
          <el-button
            type="primary"
            style="backgroundcolor: #0076f6"
            size="small"
            v-if="v_id !== 3&&v_id !== 4"
            @click="playVideo1()"
            >后置监控</el-button
          >
          <el-button
            type="primary"
            style="backgroundcolor: #0076f6"
            size="small"
            @click="tohistory()"
            >过往报警</el-button
          >
          <el-button
            type="primary"
            style="backgroundcolor: #0076f6"
            size="small"
            @click="toeqmlog()"
            >设备日志</el-button
          >
        </div>
      </div>
    </el-card>
    <div class="amap-info-sharp">
      <i class="el-icon-caret-bottom"></i>
    </div>
    <div v-draw v-if="isplay" class="playVideo drag">
      <div id="clear"></div>
      <div>
        <Play
          class="play"
          style="position: relative; margin: 3.125rem 0 0 0"
          ref="playvideo"
          :src="src"
        ></Play>
        <div class="massege" style="position: absolute; top: 0">
          <p>前置监控</p>
          <p>设备编号：{{ infocode }}</p>
          <p>当前位置：{{ infoplace }}</p>
          <el-link
            type="primary"
            icon="el-icon-close"
            @click="playClose()"
          ></el-link>
        </div>
        <div
          v-if="loading == true"
          class="loading"
          v-loading="loading"
          element-loading-text="正在开启远程摄像头拉取监控视频，请稍等...."
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
        ></div>
        <el-dropdown
          placement="top-end"
          @command="handleCommand"
          class="playmenu"
        >
          <span class="el-dropdown-link">
            清晰度<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="a">高清</el-dropdown-item>
            <el-dropdown-item command="b">流畅</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div v-draw v-if="isplay1" class="playVideo drag">
      <div>
        <play1
          class="play"
          style="position: relative; margin: 3.125rem 0 0 0"
          ref="playvideo1"
          :src="src1"
        ></play1>
        <div class="massege" style="position: absolute; top: 0">
          <p>后置监控</p>
          <p>设备编号：{{ infocode }}</p>
          <p>当前位置：{{ infoplace }}</p>
          <el-link
            type="primary"
            icon="el-icon-close"
            @click="playClose1()"
          ></el-link>
        </div>
        <div
          v-if="loading1 == true"
          class="loading"
          v-loading="loading1"
          element-loading-text="正在开启远程摄像头拉取监控视频，请稍等...."
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
        ></div>
        <el-dropdown
          placement="top-end"
          @command="handleCommand1"
          class="playmenu"
        >
          <span class="el-dropdown-link">
            清晰度<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="a">高清</el-dropdown-item>
            <el-dropdown-item command="b">流畅</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>
<script>
import {
  startplay,
  closeplay,
  looprequest,
  definition,
} from "../api/indexMapApi";
import { Notification } from "element-ui";
import Play from "../components/Play";
import play1 from "../components/play1";
export default {
  name: "Infowin",
  components: {
    Play,
    play1,
  },
  data() {
    return {
      v_id: null,
      loading: false,
      loading1: false,
      companyid: null,
      src: null,
      src1: null,
      idcode: null,
      url: "",
      isplay: false /**显示视频 */,
      isplay1: false /**显示视频 */,
      overlay: null,
      infoWindow: null,
      isInfo: true,
      infocode: null,
      infocompany: null,
      infoversion: null,
      infocloserange: null,
      infocloseapeed: null,
      infosupervisewidth: null,
      inforemotespeed: null,
      infopower: null,
      infoamount: null,
      infoplace: null,
      have_video: null,
      infotime_length: "",
      timer: null,
      timer1: null,
      roadnum: null,
      mileagenum: null,
      roadtogo: null,
      drivingto: null,
    };
  },
  mounted() {},

  methods: {
    initialize(e) {
      this.companyid = e.companyid;
      this.idcode = e.idcode; /**设备id */
      this.overlay = e.overlay;
      this.infoWindow = e.infoWindow;
      this.infoplace = e.infoplace; /**位置 */
      this.infocode = e.infocode; /**设备编号 */
      this.infocompany = e.infocompany; /**公司 */
      this.infoversion = e.infoversion; /**型号 */
      this.infocloserange = e.infocloserange; /**近报警距离 */
      this.infocloseapeed = e.infocloseapeed; /**近报警速度 */
      this.infosupervisewidth = e.infosupervisewidth; /**路面宽度 */
      this.inforemotespeed = e.inforemotespeed; /**远距报警速度 */
      this.infopower = e.infopower; /**剩余电量 */
      this.infoamount = e.infoamount; /**闯入计数 */
      this.have_video = e.have_video; /**是否有视频 */
      this.infotime_length = e.infotime_length;
      this.v_id = e.info_v_id;
      this.roadnum = e.roadnum;
      this.mileagenum = e.mileagenum;
      this.roadtogo = e.roadtogo;
      this.drivingto = e.drivingto;
      console.log(e.infotime_length, "时间");
      console.log(e.have_video, "视频");
    },
    //关闭
    close() {
      this.infoWindow.close();
    },
    // edit() {
    //   console.log("编辑按钮测试");
    // },
    // del() {
    //   console.log("删除按钮测试");
    // },
    /**播放视频 */
    playVideo() {
      this.isplay = !this.isplay;
      this.loading = true;
      var param = new URLSearchParams();
      console.log(this.infocode, "视频code");
      param.append("code", this.infocode);
      param.append("cap", "head");
      param.append("resolution", "");
      startplay(param).then((res) => {
        if (res.data.code == 200) {
          console.log(res);
          this.src = res.data.pcurl;
          this.$refs.playvideo.play(res.data.pcurl);
          if (this.timer !== null) {
            clearTimeout(this.timer);
            this.timer = null;
          } else {
            var startTime = new Date().getTime();
            var count = 0;
            let that = this;
            function fixed() {
              if (document.getElementById("clear") === null) {
                clearTimeout(that.timer);
                that.timer = null;
                that.isplay = false;
                return false;
              } else {
                count++;
                var offset = new Date().getTime() - (startTime + count * 20000);
                var nextTime = 20000 - offset;
                if (nextTime < 0) nextTime = 0;
                setTimeout(fixed, nextTime);
                that.loopvideo();
              }
            }
            this.timer = setTimeout(fixed, 20000);
          }
          this.$nextTick(function () {
            setTimeout(() => {
              this.$refs.playvideo.init();
              this.loading = false;
            }, 40000);
          });
        } else if (res.data.code == 5027) {
          this.loading = false;
          this.$message({
            showClose: true,
            message: "设备通信断开，请及时查看",
            type: "warning",
          });
        }
      });
    },
    playVideo1() {
      this.isplay1 = !this.isplay1;
      this.loading1 = true;
      var param = new URLSearchParams();
      console.log(this.infocode, "视频code");
      param.append("code", this.infocode);

      param.append("resolution", "");

      startplay(param).then((res) => {
        // console.log(res);
        // console.log(res.data.pcurl, "这是返回的url");

        if (res.data.code == 200) {
          console.log(res);
          this.src1 = res.data.pcurl;
          // this.$refs.playvideo.videoSrc = res.data.pcurl;
          this.$refs.playvideo1.play(res.data.pcurl);
          if (this.timer1 !== null) {
            debugger;
            clearInterval(this.timer1);
            console.log("测试直播心跳包");
          } else {
            this.timer1 = setInterval(() => {
              if (document.getElementById("div") === null) {
                clearInterval(this.timer1);
                this.isplay1 = false;
              }
              this.loopvideo1();
            }, 20000);
          }
          this.$nextTick(function () {
            setTimeout(() => {
              this.$refs.playvideo1.init();
              this.loading1 = false;
            }, 40000);
          });
        } else if (res.data.code == 5027) {
          this.loading1 = false;
          this.$message({
            showClose: true,
            message: "设备通信断开，请及时查看",
            type: "warning",
          });
        }
      });
    },
    /**关闭视频框 */
    playClose() {
      clearTimeout(this.timer);
      this.timer = null;
      var param = new URLSearchParams();
      param.append("code", this.infocode);
      param.append("cap", "head");
      closeplay(param).then((res) => {
        console.log(res, "关闭返回");

        if (res.status === 200) {
          console.log("关闭");
        } else if (res.code == 5027) {
          this.$message({
            showClose: true,
            message: "设备通信断开，请及时查看",
            type: "warning",
          });
        }
      });
      if ((this.isplay = true)) {
        this.isplay = !this.isplay;
      }
    },
    playClose1() {
      clearInterval(this.timer1);
      var param = new URLSearchParams();
      param.append("code", this.infocode);

      closeplay(param).then((res) => {
        console.log(res);
        console.log(res.data.pcurl);
        if (res.status === 200) {
          console.log("关闭");
        } else if (res.code == 5027) {
          this.$message({
            showClose: true,
            message: "设备通信断开，请及时查看",
            type: "warning",
          });
        }
      });
      if ((this.isplay = true)) {
        this.isplay1 = !this.isplay1;
      }
    },
    /**切换清晰度 */
    handleCommand(command) {
      console.log(command);
      if (command == "a") {
        var param = new URLSearchParams();
        param.append("code", this.infocode);
        param.append("cap", "head");
        param.append("resolution", "high");
        looprequest(param)
          .then((res) => {
            if (res.data.code == 200) {
              this.$refs.playvideo.play(res.data.pcurl);
              this.$message({
                showClose: true,
                message: "正在切换至高清·",
                type: "success",
              });
            } else if (res.data.code == 5027) {
              this.$message({
                showClose: true,
                message: "切换失败",
                type: "success",
              });
            }
          })
          .catch((e) => {});
      }
      if (command == "b") {
        var param = new URLSearchParams();
        param.append("code", this.infocode);
        param.append("cap", "head");
        param.append("resolution", "");
        looprequest(param)
          .then((res) => {
            if (res.data.code == 200) {
              console.log(res);
              this.$refs.playvideo.play(res.data.pcurl);
              this.$message({
                showClose: true,
                message: "正在切换至流畅",
                type: "success",
              });
            } else if (res.data.code == 5027) {
              this.$message({
                showClose: true,
                message: "切换失败",
                type: "success",
              });
            }
          })
          .catch((e) => {});
      }
    },
    handleCommand1(command) {
      console.log(command);
      if (command == "a") {
        var param = new URLSearchParams();
        param.append("code", this.infocode);
        param.append("resolution", "high");

        looprequest(param)
          .then((res) => {
            if (res.data.code == 200) {
              this.$refs.playvideo.play(res.data.pcurl);
              this.$message({
                showClose: true,
                message: "正在切换至高清·",
                type: "success",
              });
            } else if (res.data.code == 5027) {
              this.$message({
                showClose: true,
                message: "切换失败",
                type: "success",
              });
            }
          })
          .catch((e) => {});
      }
      if (command == "b") {
        var param = new URLSearchParams();
        param.append("code", this.infocode);
        param.append("resolution", "");
        looprequest(param)
          .then((res) => {
            if (res.data.code == 200) {
              console.log(res);
              this.$refs.playvideo.play(res.data.pcurl);
              this.$message({
                showClose: true,
                message: "正在切换至流畅",
                type: "success",
              });
            } else if (res.data.code == 5027) {
              this.$message({
                showClose: true,
                message: "切换失败",
                type: "success",
              });
            }
          })
          .catch((e) => {});
      }
    },
    /**过往报警 */
    tohistory() {
      if (this.infocode != "") {
        this.$router.push({
          name: "adalarmHistory",
          params: {
            code: this.idcode,
            companyid: this.companyid,
          },
        });
      }
    },
    /**设备日志 */
    toeqmlog() {
      if (this.infocode != "") {
        this.$router.push({
          name: "adequipmentLog",
          params: {
            code: this.idcode,
            companyid: this.companyid,
          },
        });
      }
    },
    /**循环请求 */
    loopvideo() {
      console.log("循环请求");
      var param = new URLSearchParams();
      param.append("code", this.infocode);
      param.append("cap", "head");
      looprequest(param)
        .then((res) => {
          // console.log(res);
          // console.log(res.data.pcurl, "这是返回的url");

          if (res.status == 200) {
            console.log(res, "前置循环返回");
          } else if (res.data.code == 5027) {
          }
        })
        .catch((e) => {});
    },
    loopvideo1() {
      console.log("循环请求");
      var param = new URLSearchParams();
      param.append("code", this.infocode);

      looprequest(param)
        .then((res) => {
          // console.log(res);
          // console.log(res.data.pcurl, "这是返回的url");

          if (res.status == 200) {
            console.log(res);
          } else if (res.data.code == 5027) {
          }
        })
        .catch((e) => {});
    },
    clearloop() {
      clearInterval(this.timer);
    },
  },
  destroyed() {
    clearTimeout(this.timer);
    clearInterval(this.timer1);
  },
};
</script>
 
<style lang="css" scoped>
.video-js .vjs-big-play-button {
  font-size: 3em;
  line-height: 1.5em;
  height: 1.5em;
  width: 3em;
  display: block;
  position: absolute;
  top: 170px;
  left: 360px;
  padding: 0;
  cursor: pointer;
  opacity: 1;
  border: 0.06666em solid #fff;
  background-color: #2b333f;
  background-color: rgba(43, 51, 63, 0.7);
  border-radius: 0.3em;
  transition: all 0.4s;
}
@media only screen and (max-width: 1920px) {
  .amap-info-sharp {
    bottom: -0.0625rem;
    left: 48.5%;
    position: absolute;
    color: #fff;
    z-index: -1;
    background-color: coral;
  }
  .info_mess {
    width: 100%;
    /* background-color: burlywood; */
    border-spacing: 0.5rem;
    border: none;
    margin-bottom: 0.625rem;
    font-family: "SourceHanSansSC-";
    margin-left: -0.625rem;
  }
  .info_mess tr td {
    word-wrap: break-word;
    word-break: break-all;
    /* text-align: start; */
    border: none;
    font-size: 0.9375rem;
    font-family: "SourceHanSansSC-" !important;
  }
  .mess_left {
    font-size: 0.875rem;
    text-align: end;
    font-weight: bold;
    margin-top: 0.3125rem;
    /* background-color: aquamarine; */
  }
  .left_bottom {
    width: 3.9975rem;
  }
  .right_bottom {
    width: 3.125rem;
  }
  .mess_right {
    margin-top: 0.3125rem;
    padding: 0 0 0 0.625rem;
    text-align: start;
    /* background-color: chartreuse; */
  }
  #del-div {
    position: absolute;
    right: 1.25rem;
    top: 1.25rem;
    transform: scale(1.2);
    color: #000000;
    /* background-color: cyan; */
  }
  #del-div .el-link {
    color: #000000 !important;
  }
  .massege .el-link {
    color: #ffffff !important;
  }
  .el-card {
    width: 26.5625rem !important;
  }
  #infoWindowTool {
    display: flex;
    align-items: center;
    justify-content: space-around;
    /* background-color: darkgreen;
  width: 100%; */
  }
  .el-button {
  }
  .play {
    width: 50rem;
    height: 28.125rem;
    border: 0.0625rem solid black;
    background-color: cadetblue;
    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
  }
  .loading {
    width: 50rem;
    height: 28.125rem;
    position: absolute;
    z-index: 15;
    top: 0;
    left: 0;
  }
  .playmenu {
    position: absolute;
    z-index: 15;
    right: 0.9375rem;
    bottom: 0.3125rem;
  }
  element.style {
    top: 12.5rem;
    left: 12.5rem;

    z-index: 10;
  }
  .close {
    width: 2.5rem;
    height: 2.5rem;
    text-align: center;
    line-height: 2.5rem;
    z-index: 20;
    background-color: crimson;
  }
  .drag {
    top: 9.375rem;
    left: 16.25rem;
  }
  .massege {
    width: 50rem;
    height: 3.125rem;
    background-color: black;
    border: 0.0625rem solid black;
    z-index: 9999;
    color: white;
    font-family: "楷体";
  }
  .massege p {
    height: 3.125rem;
    line-height: 3.125rem;
    float: left;
    background-color: black;
    border: 0rem solid black;
    text-align: center;
    padding: 0 0.3125rem 0 0.3125rem;
  }
  .massege .el-link {
    float: right;
    width: 3.125rem;
    height: 3.125rem;
  }
  .el-card__body {
    padding: 0.625rem;
  }

  /* .playVideo{
  z-index: 20;
} */
}
@media only screen and (max-width: 1081px) {
  .amap-info-sharp {
    bottom: -0.0625rem;
    left: 48.5%;
    position: absolute;
    color: #fff;
    z-index: -1;
    background-color: coral;
  }
  .info_mess {
    width: 100%;
    /* background-color: burlywood; */
    border-spacing: 0.5rem;
    border: none;
    margin-bottom: 0.625rem;
    font-family: "SourceHanSansSC-";
    margin-left: -0.625rem;
  }
  .info_mess tr td {
    word-wrap: break-word;
    word-break: break-all;
    /* text-align: start; */
    border: none;

    font-size: 1.1rem;

    font-family: "SourceHanSansSC-" !important;
  }
  .mess_left {
    text-align: end;
    font-weight: bold;
    margin-top: 0.3125rem;

    /* background-color: aquamarine; */
  }
  .left_bottom {
    width: 2.6875rem;
  }
  .right_bottom {
    width: 3.125rem;
  }
  .mess_right {
    margin-top: 0.3125rem;
    padding: 0 0 0 0.625rem;
    text-align: start;

    /* background-color: chartreuse; */
  }
  #del-div {
    position: absolute;
    right: 1.25rem;
    top: 1.25rem;
    transform: scale(1.2);
    color: #000000;
    /* background-color: cyan; */
  }
  #del-div .el-link {
    color: #000000 !important;
  }
  .massege .el-link {
    color: #ffffff !important;
  }
  .el-card {
    width: 34.5625rem !important;
  }
  #infoWindowTool {
    display: flex;
    align-items: center;
    justify-content: space-around;
    /* background-color: darkgreen;
  width: 100%; */
  }
  .el-button {
  }
  .play {
    width: 50rem;
    height: 28.125rem;
    border: 0.0625rem solid black;
    background-color: cadetblue;
    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
  }
  .loading {
    width: 50rem;
    height: 28.125rem;
    position: absolute;
    z-index: 15;
    top: 0;
    left: 0;
  }
  .playmenu {
    position: absolute;
    z-index: 15;
    right: 0.9375rem;
    bottom: 0.3125rem;
  }
  element.style {
    top: 12.5rem;
    left: 12.5rem;

    z-index: 10;
  }
  .close {
    width: 2.5rem;
    height: 2.5rem;
    text-align: center;
    line-height: 2.5rem;
    z-index: 20;
    background-color: crimson;
  }
  .drag {
    top: 9.375rem;
    left: 16.25rem;
  }
  .massege {
    width: 50rem;
    height: 3.125rem;
    background-color: black;
    border: 0.0625rem solid black;
    z-index: 9999;
    color: white;
    font-family: "楷体";
  }
  .massege p {
    height: 3.125rem;
    line-height: 3.125rem;
    float: left;
    background-color: black;
    border: 0rem solid black;
    text-align: center;
    padding: 0 0.3125rem 0 0.3125rem;
  }
  .massege .el-link {
    float: right;
    width: 3.125rem;
    height: 3.125rem;
  }
  .el-card__body {
    padding: 0.625rem;
  }
}
</style>