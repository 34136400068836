<template>
  <div id="map_container">
    <div class="eqmcount">
      <ul class="eqmcount_num">
        <li>设备总数:</li>
        <li class="numcount" style="width: 70px">{{ devices_amount }}</li>
      </ul>
      <ul class="eqmcount_num">
        <li>在线数量:</li>
        <li style="width: 70px">{{ online_cunt }}</li>
      </ul>
    </div>
    <div class="map_linecolor" v-if="userRoleId === '1' || userRoleId === '2'">
      <div class="map_linecolor_left">
        <p>查看公司设备：</p>
        <el-select
          v-if="userRoleId === '1' || userRoleId === '2'"
          v-model="company_id"
          clearable
          @change="selectcompany($event)"
          placeholder="请选择公司名称"
          style="width: 60%"
        >
          <el-option
            v-for="item in options"
            :key="item.company_id"
            :label="item.company_name"
            :value="item.company_id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="map_linecolor_right">
        <p>实时路况:</p>
        <p>畅通</p>
        <ul class="map_linecolor_ul">
          <li style="background-color: #34b000"></li>
          <li style="background-color: #fecb00"></li>
          <li style="background-color: #df0100"></li>
          <li style="background-color: #8e0e0b"></li>
        </ul>
        <p>拥堵</p>
      </div>
    </div>
    <div v-else class="user_panle">
      <div class="useritem">
        <p>实时路况:</p>
        <p>畅通</p>
        <ul class="map_linecolor_ul">
          <li style="background-color: #34b000"></li>
          <li style="background-color: #fecb00"></li>
          <li style="background-color: #df0100"></li>
          <li style="background-color: #8e0e0b"></li>
        </ul>
        <p>拥堵</p>
      </div>
    </div>

    <!-- <div class="count" ref="countup"></div> -->
    <div id="info" style="display: none">
      <Infowin ref="infowin"></Infowin>
    </div>

    <!-- <div class="drag" v-draw><Play class="play"></Play></div> -->
  </div>
</template>

<script>
// @ is an alias to /
import { user, info, companymarker } from "../../api/indexMapApi";
import Infowin from "../../components/infowin";

// import Play from "../components/Play.vue";
export default {
  components: {
    // HelloWorld
    Infowin,
    // Play,
  },
  data() {
    return {
      options: {
        startVal: 0,
      },
      endCount: 100,

      /**公司下拉选择框 */
      options: [
        {
          company_id: 1,
          company_name: "无数据",
        },
      ],
      company_id: "",
      devices_amount: null,
      online_cunt: null,
      gaodedress: " ",
      code: " ",
      timer: null /**计时器，每30秒更新数据 */,
      userRoleId: null,
      markers: [],
    };
  },
  created() {
    this.userRoleId = this.$cookies.get("userRoleId");
  },
  mounted() {
    this.initMap();
    this.info();
    if (this.timer !== null) {
      clearInterval(this.timer);
    } else {
      this.timer = setInterval(() => {
        this.info();
      }, 30000);
    }
  },
  methods: {
    test(v) {
      console.log(v);
    },
    info() {
      this.userRoleId = this.$cookies.get("userRoleId");
      this.map.on("complete", () => {
        var that = this;
        // var text = new AMap.InfoWindow({offset: new AMap.Pixel(0, -30)});
        var infoWindow = new AMap.InfoWindow({
          isCustom: true, //使用自定义窗体
          content: that.$refs.infowin.$el,
          offset: new AMap.Pixel(0, -30),
          closeWhenClickMap: true,
          closeWhenClickMap: false,
        });
        // var icon = new AMap.Icon({
        //   size: new AMap.Size(40, 50), // 图标尺寸
        //   image: "../../../public/img/icon.png", // Icon的图像
        //   imageOffset: new AMap.Pixel(0, -60), // 图像相对展示区域的偏移量，适于雪碧图等
        //   imageSize: new AMap.Size(40, 50), // 根据所设置的大小拉伸或压缩图片
        // });
        console.log("这是2");
        user()
          .then((res) => {
            if (res.data.code == 200) {
              this.online_cunt =
                res.data.online_count != " " ? res.data.online_count : "暂无";
              this.devices_amount =
                res.data.devices_amount != " "
                  ? res.data.devices_amount
                  : "暂无";
              console.log('单位',res.data);
              this.options = res.data.companies;
              var toGcj = [];
              for (var i = 0; i < res.data.station_info.length; i++) {
                if (res.data.station_info[i].longlat[0] != "0") {
                  let marker = new AMap.Marker({
                    position: this.Gps84_To_Gcj02(
                      res.data.station_info[i].longlat[0],
                      res.data.station_info[i].longlat[1]
                    ), //不同标记点的经纬度
                    map: this.map,
                    // icon: require("public/img/icon.png"),
                    icon: require("../../../public/img/icon.png"),
                    draggable: false,
                    offset: new AMap.Pixel(-13, -30),
                  });
                  console.log(res.data.station_info[i], "lable");
                  let lable = res.data.station_info[i];

                  this.markers.push(marker);

                  // if(lable.upordown=='0'){
                  //    var upordown='上行'
                  //  }else if(lable.upordown=='1'){
                  //    var upordown='下行'
                  //  }else{
                  //    var upordown='无'
                  //  }
                  marker.setLabel({
                    offset: new AMap.Pixel(-5, -10),
                    content: ` <table><tr><td style="font-weight: bold;font-size: 14px;">道路编号：</td><td style="font-size: 14px;padding:2px;">${
                      lable.road_name != null ? lable.road_name : "无"
                    }</td><td style="font-weight: bold;font-size: 14px;">道路去向：</td><td style="font-size: 14px;padding: 0px 5px 0px 2px;">${
                      lable.toward != null ? lable.toward : "无"
                    }</td></tr><tr><td style="font-size: 14px;font-weight: bold;">里程桩号：</td><td style="font-size: 14px;padding: 2px;">${
                      lable.mileage != null ? lable.mileage : "无"
                    }</td><td style="font-weight: bold;font-size: 14px;">施工内容:</td><td style="font-size: 14px;padding: 0px 5px 0px 2px;">${lable.upordown}</td></tr></table>`,
                    // content: `<div class="info" ">今日时长:15小时</div>`,
                    direction: "top",
                  });
                  marker.on("mouseover", markerclick);
                  // marker.on("mouseout", markerout);
                  var _this = this;
                  marker.code = res.data.station_info[i].id;
                  marker.place = res.data.station_info[i].place;
                  function markerclick(e) {
                    console.log(e.target.code);
                    let code = e.target.code;
                    info(code)
                      .then((res) => {
                        console.log(res);
                        var infomessage = res.data.station_info[0];
                        console.log(infomessage);
                        console.log("这是信息框");
                        _this.infoWindow = infoWindow;
                        _this.infoWindow.open(
                          _this.map,
                          e.target.getPosition()
                        );
                        _this.$refs.infowin.initialize({
                          companyid: infomessage.companyid,
                          idcode:
                            infomessage.id != ""
                              ? infomessage.id
                              : "暂无数据" /**设备id */,
                          overlay: e.target,
                          infoWindow: _this.infoWindow,
                          infocode:
                            infomessage.code != ""
                              ? infomessage.code
                              : "暂无数据" /**设备编号 */,
                          infoplace:
                            infomessage.place != ""
                              ? infomessage.place
                              : "暂无数据" /**位置 */,
                          infocompany:
                            infomessage.company != ""
                              ? infomessage.company
                              : "暂无数据" /**公司 */,
                          infoversion:
                            infomessage.version != ""
                              ? infomessage.version
                              : "暂无数据" /**型号 */,
                          infocloserange:
                            infomessage.close_range != ""
                              ? infomessage.close_range + "m"
                              : "暂无数据" /**近报警距离 */,
                          infocloseapeed:
                            infomessage.close_speed != ""
                              ? infomessage.close_speed + "km/h"
                              : "暂无数据" /**近报警速度 */,
                          infosupervisewidth:
                            infomessage.supervise_width != ""
                              ? infomessage.supervise_width + "m"
                              : "暂无数据" /**路面宽度 */,
                          inforemotespeed:
                            infomessage.remote_speed != ""
                              ? infomessage.remote_speed + "km/h"
                              : "暂无数据" /**远距报警速度 */,
                          infopower:
                            infomessage.power != null
                              ? infomessage.power
                              : "暂无数据" /**剩余电量 */,
                          infoamount: infomessage.amount
                            ? infomessage.amount + "次"
                            : "暂无数据" /**闯入计数 */,
                          info_v_id: infomessage.version_id,
                          have_video: infomessage.have_video /**是否有直播 */,
                          hls_url: infomessage.hls /**hls拉流地址 */,
                          infotime_length: _this.changetime(
                            infomessage.time_length
                          ),
                          roadnum: infomessage.road_name,
                          mileagenum: infomessage.mileage,
                          roadtogo: infomessage.toward,
                          drivingto: infomessage.upordown,
                        });
                      })
                      .catch((e) => {
                        this.$message.error(e);
                      });

                    // infoWindow.setAnchor("bottom-left");
                  }
                  // function markerout() {
                  //   _this.infoWindow.close();
                  // }
                  this.map.setFitView();
                }
              }
              console.log('options',this.options)
            }
          })
          .catch((e) => {
            console.log(e);
            this.$message.error(e.message);
          });
      });
    },
    /**时长转换 */
    changetime(str) {
      if (str) {
        return str < 60
          ? str.toString() + "分钟"
          : str % 60 === 0
          ? (str / 60).toString() + "小时"
          : Math.floor(str / 60).toString() +
            "小时" +
            (str % 60).toString() +
            "分钟";
      } else {
        return "1小时";
      }
    },
    /**选择公司 */
    selectcompany(e) {
      console.log(e);
      this.map.clearMap();
      // this.map.remove(this.markers);
      companymarker(e)
        .then((res) => {
          console.log(res);
          console.log("这是选择公司");
          if (res.data.code == 200) {
            var infoWindow = new AMap.InfoWindow({
              isCustom: true, //使用自定义窗体
              content: this.$refs.infowin.$el,
              offset: new AMap.Pixel(0, -25),
              closeWhenClickMap: true,
            });
            console.log(res.data);
            this.options = res.data.companies;
            var toGcj = [];
            if (res.data.station_info.length == 0) {
              this.$message({
                showClose: true,
                message: "当前所选公司暂无设备",
                type: "warning",
              });
            }
            for (var i = 0; i < res.data.station_info.length; i++) {
              if (res.data.station_info[i].longlat[0] != "0") {
                let marker = new AMap.Marker({
                  position: this.Gps84_To_Gcj02(
                    res.data.station_info[i].longlat[0],
                    res.data.station_info[i].longlat[1]
                  ), //不同标记点的经纬度
                  map: this.map,
                  // icon: require("public/img/icon.png"),
                  icon: require("../../../public/img/icon.png"),
                  draggable: false,
                  offset: new AMap.Pixel(-13, -30),
                });
                console.log(res.data.station_info[i], "lable");
                let lable = res.data.station_info[i];

                this.markers.push(marker);
                if (lable.upordown == "0") {
                  var upordown = "上行";
                } else if (lable.upordown == "1") {
                  var upordown = "下行";
                } else {
                  var upordown = "无";
                }
                marker.setLabel({
                  offset: new AMap.Pixel(-5, -10),
                  content: ` <table><tr><td style="font-weight: bold;font-size: 14px;">道路编号：</td><td style="font-size: 14px;padding:2px;">${
                    lable.road_name != null ? lable.road_name : "无"
                  }</td><td style="font-weight: bold;font-size: 14px;">道路去向：</td><td style="font-size: 14px;padding: 0px 5px 0px 2px;">${
                    lable.toward != null ? lable.toward : "无"
                  }</td></tr><tr><td style="font-size: 14px;font-weight: bold;">里程桩号：</td><td style="font-size: 14px;padding: 2px;">${
                    lable.mileage != null ? lable.mileage : "无"
                  }</td><td style="font-weight: bold;font-size: 14px;">行驶方向：</td><td style="font-size: 14px;padding: 0px 5px 0px 2px;">${upordown}</td></tr></table>`,
                  // content: `<div class="info" ">今日时长:15小时</div>`,
                  direction: "top",
                });
                marker.on("mouseover", markerclick);
                // marker.on("mouseout", markerout);
                var _this = this;
                marker.code = res.data.station_info[i].id;
                marker.place = res.data.station_info[i].place;
                function markerclick(e) {
                  console.log(e.target.code);
                  let code = e.target.code;
                  info(code)
                    .then((res) => {
                      console.log(res);
                      var infomessage = res.data.station_info[0];
                      console.log(infomessage);
                      console.log("这是信息框");
                      _this.infoWindow = infoWindow;
                      _this.infoWindow.open(_this.map, e.target.getPosition());
                      _this.$refs.infowin.initialize({
                        companyid: infomessage.companyid,
                        idcode:
                          infomessage.id != ""
                            ? infomessage.id
                            : "暂无数据" /**设备id */,
                        overlay: e.target,
                        infoWindow: _this.infoWindow,
                        infocode:
                          infomessage.code != ""
                            ? infomessage.code
                            : "暂无数据" /**设备编号 */,
                        infoplace:
                          infomessage.place != ""
                            ? infomessage.place
                            : "暂无数据" /**位置 */,
                        infocompany:
                          infomessage.company != ""
                            ? infomessage.company
                            : "暂无数据" /**公司 */,
                        infoversion:
                          infomessage.version != ""
                            ? infomessage.version
                            : "暂无数据" /**型号 */,
                        infocloserange:
                          infomessage.close_range != ""
                            ? infomessage.close_range + "m"
                            : "暂无数据" /**近报警距离 */,
                        infocloseapeed:
                          infomessage.close_speed != ""
                            ? infomessage.close_speed + "km/h"
                            : "暂无数据" /**近报警速度 */,
                        infosupervisewidth:
                          infomessage.supervise_width != ""
                            ? infomessage.supervise_width + "m"
                            : "暂无数据" /**路面宽度 */,
                        inforemotespeed:
                          infomessage.remote_speed != ""
                            ? infomessage.remote_speed + "km/h"
                            : "暂无数据" /**远距报警速度 */,
                        infopower:
                          infomessage.power != null
                            ? infomessage.power
                            : "暂无数据" /**剩余电量 */,
                        infoamount: infomessage.amount
                          ? infomessage.amount + "次"
                          : "暂无数据" /**闯入计数 */,
                        info_v_id: infomessage.version_id,
                        have_video: infomessage.have_video /**是否有直播 */,
                        hls_url: infomessage.hls /**hls拉流地址 */,
                        infotime_length: _this.changetime(
                          infomessage.time_length
                        ),
                        roadnum: infomessage.road_name,
                        mileagenum: infomessage.mileage,
                        roadtogo: infomessage.toward,
                        drivingto: infomessage.upordown,
                      });
                    })
                    .catch((e) => {
                      this.$message.error(e);
                    });

                  // infoWindow.setAnchor("bottom-left");
                }
                // function markerout() {
                //   _this.infoWindow.close();
                // }
                this.map.setFitView();
              }
            }
          }
        })
        .catch((e) => {
          this.$message.error(e.message);
        });
    },
  },
  destroyed() {
    clearInterval(this.timer);
  },
};
</script>
<style scoped>
#map_container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #161e2f;
}
.eqmcount {
  position: absolute;
  z-index: 9;
  top: 20px;
  left: 50px;
  background-color: #ebeff5;
  border-radius: 10px;
  filter: drop-shadow(8px 4px 4px rgb(0, 0, 0, 0.09));
  width: 300px;
  height: 50px;
  text-align: center;
  font-family: "SourceHanSansSC-";
  font-size: 16px;
  color: #333333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 0 28px;
  /* background-color: blueviolet; */
}
.eqmcount_num {
  /* width: 50%; */
  /* background-color: cadetblue; */
}
.eqmcount li {
  float: left;
  /* background-color: rosybrown; */
  font-size: 16px;
}
.numcount {
  border-right: 1px solid #999999;
}
#map_container .map_linecolor {
  /* border: 1px solid black; */
  background-color: #ebeff5;
  filter: drop-shadow(8px 4px 4px rgb(0, 0, 0, 0.09));
  border-radius: 10px;
  position: absolute;
  z-index: 8;
  right: 88px;
  top: 20px;
  width: 800px;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 40px 0px 40px;
}
.map_linecolor_left {
  height: 50%;
  width: 40%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-right: 1px solid #999999;
  padding: 0 10px 0 0;
}
.map_linecolor_right {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 55%;
  padding: 0 0 0 16px;
}
#map_container .map_linecolor p {
  text-align: center;
  font-size: 16px;
  /* background-color: darkslategray; */
  /* border-right:1px solid black ; */
}
#map_container .map_linecolor .map_linecolor_ul {
  width: 45%;
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-content: center;
  /* background-color: rgb(55, 14, 133); */
}
#map_container .map_linecolor .map_linecolor_ul li {
  /* float: left; */
  width: 25px;
  margin: 20px 1px;
  height: 6px;
}
.user_panle {
  /* border: 1px solid black; */
  background-color: #ebeff5;
  filter: drop-shadow(8px 4px 4px rgb(0, 0, 0, 0.09));
  border-radius: 10px;
  position: absolute;
  z-index: 8;
  right: 88px;
  top: 20px;
  width: 320px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 40px 0px 40px;
}
.useritem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.useritem ul {
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-content: center;
}
.useritem ul li {
  width: 25px;
  margin: 20px 1px;
  height: 6px;
}
.el-select-dropdown {
  background-color: cadetblue !important;
  margin: 0px;
  border: 0px;
  border-radius: 0px;
}

.el-select {
  z-index: 100;
}

.content-window-card {
  position: relative;
  box-shadow: none;
  bottom: 0;
  left: 0;
  width: auto;
  padding: 0;
}

.content-window-card p {
  height: 2rem;
}

.custom-info {
  border: solid 1px silver;
}

div.info-top {
  position: relative;
  background: none repeat scroll 0 0 #f9f9f9;
  border-bottom: 1px solid #ccc;
  border-radius: 5px 5px 0 0;
}

div.info-top div {
  display: inline-block;
  color: #333333;
  font-size: 14px;
  font-weight: bold;
  line-height: 31px;
  padding: 0 10px;
}

div.info-top img {
  position: absolute;
  top: 10px;
  right: 10px;
  transition-duration: 0.25s;
}

div.info-top img:hover {
  box-shadow: 0px 0px 5px #000;
}

div.info-middle {
  font-size: 12px;
  padding: 10px 6px;
  line-height: 20px;
}

div.info-bottom {
  height: 0px;
  width: 100%;
  clear: both;
  text-align: center;
}

div.info-bottom img {
  position: relative;
  z-index: 104;
}

span {
  margin-left: 5px;
  font-size: 11px;
}

.info-middle img {
  float: left;
  margin-right: 6px;
}
#info {
  position: absolute;
  background: black;
  z-index: 20;
}
.play {
  width: 800px;
  height: 450px;
  border: 1px solid black;
  background-color: cadetblue;
  position: absolute;
  z-index: 9;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}
element.style {
  top: 500px;
  left: 500px;
  z-index: 10;
}
@media only screen and (max-width: 1920px) {
  #map_container {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #161e2f;
  }
  .eqmcount {
    position: absolute;
    z-index: 9;
    top: 1.25rem;
    left: 3.125rem;
    background-color: #ebeff5;
    border-radius: 0.625rem;
    filter: drop-shadow(0.5rem 0.25rem 0.25rem rgb(0, 0, 0, 0.09));
    width: 18.75rem;
    height: 3.125rem;
    text-align: center;
    font-family: "SourceHanSansSC-";
    font-size: 1rem;
    color: #333333;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.625rem 0 1.75rem;
    /* background-color: blueviolet; */
  }
  .eqmcount_num {
    /* width: 50%; */
    /* background-color: cadetblue; */
  }
  .eqmcount li {
    float: left;
    /* background-color: rosybrown; */
    font-size: 1rem;
  }
  .numcount {
    border-right: 0.0625rem solid #999999;
  }
  #map_container .map_linecolor {
    /* border: 0.0625rem solid black; */
    background-color: #ebeff5;
    filter: drop-shadow(0.5rem 0.25rem 0.25rem rgb(0, 0, 0, 0.09));
    border-radius: 0.625rem;
    position: absolute;
    z-index: 8;
    right: 5.5rem;
    top: 1.25rem;
    width: 50rem;
    height: 3.125rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 2.5rem 0rem 2.5rem;
  }
  .map_linecolor_left {
    height: 50%;
    width: 40%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-right: 0.0625rem solid #999999;
    padding: 0 0.625rem 0 0;
  }
  .map_linecolor_right {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 55%;
    padding: 0 0 0 1rem;
  }
  #map_container .map_linecolor p {
    text-align: center;
    font-size: 1rem;
    /* background-color: darkslategray; */
    /* border-right:0.0625rem solid black ; */
  }
  #map_container .map_linecolor .map_linecolor_ul {
    width: 45%;
    height: 2.75rem;
    display: flex;
    justify-content: space-between;
    align-content: center;
    /* background-color: rgb(55, 14, 133); */
  }
  #map_container .map_linecolor .map_linecolor_ul li {
    /* float: left; */
    width: 1.5625rem;
    margin: 1.25rem 0.0625rem;
    height: 0.375rem;
  }
  .user_panle {
    /* border: 0.0625rem solid black; */
    background-color: #ebeff5;
    filter: drop-shadow(0.5rem 0.25rem 0.25rem rgb(0, 0, 0, 0.09));
    border-radius: 0.625rem;
    position: absolute;
    z-index: 8;
    right: 5.5rem;
    top: 1.25rem;
    width: 20rem;
    height: 3.125rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0rem 2.5rem 0rem 2.5rem;
  }
  .useritem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .useritem ul {
    height: 2.75rem;
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
  .useritem ul li {
    width: 1.5625rem;
    margin: 1.25rem 0.0625rem;
    height: 0.375rem;
  }
  .el-select-dropdown {
    background-color: cadetblue !important;
    margin: 0rem;
    border: 0rem;
    border-radius: 0rem;
  }

  .el-select {
    z-index: 100;
  }

  .content-window-card {
    position: relative;
    box-shadow: none;
    bottom: 0;
    left: 0;
    width: auto;
    padding: 0;
  }

  .content-window-card p {
    height: 32px;
  }

  .custom-info {
    border: solid 0.0625rem silver;
  }

  div.info-top {
    position: relative;
    background: none repeat scroll 0 0 #f9f9f9;
    border-bottom: 0.0625rem solid #ccc;
    border-radius: 0.3125rem 0.3125rem 0 0;
  }

  div.info-top div {
    display: inline-block;
    color: #333333;
    font-size: 0.875rem;
    font-weight: bold;
    line-height: 1.9375rem;
    padding: 0 0.625rem;
  }

  div.info-top img {
    position: absolute;
    top: 0.625rem;
    right: 0.625rem;
    transition-duration: 0.25s;
  }

  div.info-top img:hover {
    box-shadow: 0rem 0rem 0.3125rem #000;
  }

  div.info-middle {
    font-size: 0.75rem;
    padding: 0.625rem 0.375rem;
    line-height: 1.25rem;
  }

  div.info-bottom {
    height: 0rem;
    width: 100%;
    clear: both;
    text-align: center;
  }

  div.info-bottom img {
    position: relative;
    z-index: 104;
  }

  span {
    margin-left: 0.3125rem;
    font-size: 0.6875rem;
  }

  .info-middle img {
    float: left;
    margin-right: 0.375rem;
  }
  #info {
    position: absolute;
    background: black;
    z-index: 20;
  }
  .play {
    width: 50rem;
    height: 28.125rem;
    border: 0.0625rem solid black;
    background-color: cadetblue;
    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
  }
  element.style {
    top: 31.25rem;
    left: 31.25rem;
    z-index: 10;
  }
}
@media only screen and (max-width: 1080px) {
  #map_container {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #161e2f;
  }
  .eqmcount {
    position: absolute;
    z-index: 9;
    top: 1.25rem;
    left: 1.125rem;
    background-color: #ebeff5;
    border-radius: 0.625rem;
    filter: drop-shadow(0.5rem 0.25rem 0.25rem rgb(0, 0, 0, 0.09));
    width: 21.75rem;
    height: 3.125rem;
    text-align: center;
    font-family: "SourceHanSansSC-";
    font-size: 1rem;
    color: #333333;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.625rem 0 1.75rem;
    /* background-color: blueviolet; */
  }
  .eqmcount_num {
    /* width: 50%; */
    /* background-color: cadetblue; */
  }
  .eqmcount li {
    float: left;
    /* background-color: rosybrown; */
    font-size: 1rem;
  }
  .numcount {
    border-right: 0.0625rem solid #999999;
  }
  #map_container .map_linecolor {
    /* border: 0.0625rem solid black; */
    background-color: #ebeff5;
    filter: drop-shadow(0.5rem 0.25rem 0.25rem rgb(0, 0, 0, 0.09));
    border-radius: 0.625rem;
    position: absolute;
    z-index: 8;
    right: 0.625rem;
    top: 1.25rem;
    width: 44.1875rem;
    height: 3.125rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 2.5rem 0rem 2.5rem;
  }
  .map_linecolor_left {
    height: 50%;
    width: 40%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-right: 0.0625rem solid #999999;
    padding: 0 0.625rem 0 0;
  }
  .map_linecolor_right {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 55%;
    padding: 0 0 0 1rem;
  }
  #map_container .map_linecolor p {
    text-align: center;
    font-size: 1rem;
    /* background-color: darkslategray; */
    /* border-right:0.0625rem solid black ; */
  }
  #map_container .map_linecolor .map_linecolor_ul {
    width: 45%;
    height: 2.75rem;
    display: flex;
    justify-content: space-between;
    align-content: center;
    /* background-color: rgb(55, 14, 133); */
  }
  #map_container .map_linecolor .map_linecolor_ul li {
    /* float: left; */
    width: 1.5625rem;
    margin: 1.25rem 0.0625rem;
    height: 0.375rem;
  }
  .user_panle {
    /* border: 0.0625rem solid black; */
    background-color: #ebeff5;
    filter: drop-shadow(0.5rem 0.25rem 0.25rem rgb(0, 0, 0, 0.09));
    border-radius: 0.625rem;
    position: absolute;
    z-index: 8;
    right: 5.5rem;
    top: 1.25rem;
    width: 20rem;
    height: 3.125rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0rem 2.5rem 0rem 2.5rem;
  }
  .useritem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .useritem ul {
    height: 2.75rem;
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
  .useritem ul li {
    width: 1.5625rem;
    margin: 1.25rem 0.0625rem;
    height: 0.375rem;
  }
  .el-select-dropdown {
    background-color: cadetblue !important;
    margin: 0rem;
    border: 0rem;
    border-radius: 0rem;
  }

  .el-select {
    z-index: 100;
  }

  .content-window-card {
    position: relative;
    box-shadow: none;
    bottom: 0;
    left: 0;
    width: auto;
    padding: 0;
  }

  .content-window-card p {
    height: 32px;
  }

  .custom-info {
    border: solid 0.0625rem silver;
  }

  div.info-top {
    position: relative;
    background: none repeat scroll 0 0 #f9f9f9;
    border-bottom: 0.0625rem solid #ccc;
    border-radius: 0.3125rem 0.3125rem 0 0;
  }

  div.info-top div {
    display: inline-block;
    color: #333333;
    font-size: 0.875rem;
    font-weight: bold;
    line-height: 1.9375rem;
    padding: 0 0.625rem;
  }

  div.info-top img {
    position: absolute;
    top: 0.625rem;
    right: 0.625rem;
    transition-duration: 0.25s;
  }

  div.info-top img:hover {
    box-shadow: 0rem 0rem 0.3125rem #000;
  }

  div.info-middle {
    font-size: 0.75rem;
    padding: 0.625rem 0.375rem;
    line-height: 1.25rem;
  }

  div.info-bottom {
    height: 0rem;
    width: 100%;
    clear: both;
    text-align: center;
  }

  div.info-bottom img {
    position: relative;
    z-index: 104;
  }

  span {
    margin-left: 0.3125rem;
    font-size: 0.6875rem;
  }

  .info-middle img {
    float: left;
    margin-right: 0.375rem;
  }
  #info {
    position: absolute;
    background: black;
    z-index: 20;
  }
  .play {
    width: 50rem;
    height: 28.125rem;
    border: 0.0625rem solid black;
    background-color: cadetblue;
    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
  }
  element.style {
    top: 31.25rem;
    left: 31.25rem;
    z-index: 10;
  }
}
</style>