<template>
  <div>
    <!-- <video-player
      class="video-player-box vjs-big-play-centered"
      ref="videoPlayer"
      :options="playerOptions"
      :playsinline="true"
      :poster="posterSrc"
      @click="onPlayerPlay($event)"
    >
    <source id="sourceBox" :src="videoSrc">
      <p class="vjs-no-js">不支持播放</p>

    </video-player> -->
    <video
      id="videobox"
      class="video-js vjs-default-skin vjs-16-9 vjs-big-play-centered .vjs-loading-spinner"
      controls
      preload="auto"
      webkit-playsinline="true"
      playsinline="true"
      type="application/x-mpegURL"
      allowsInlineMediaPlayback="YES"
      webview.allowsInlineMediaPlayback="YES"
      width="100%"
      ref="videoRef"
      x5-video-player-fullscreen="true"
      :poster="posterSrc"
    >
      <source id="sourceBox" :src="videoSrc" :type="videotype" />
      <p class="vjs-no-js">不支持播放</p>
    </video>

    <!-- 这里有对应的操作事件，这里就不一一写了 -->
    <!--      
    customEventName="customstatechangedeventname"
    @play="onPlayerPlay($event)"
    @pause="onPlayerPause($event)"
    @ended="onPlayerEnded($event)"
    @waiting="onPlayerWaiting($event)"
    @playing="onPlayerPlaying($event)"
    @loadeddata="onPlayerLoadeddata($event)"
    @timeupdate="onPlayerTimeupdate($event)"
    @canplay="onPlayerCanplay($event)"
    @canplaythrough="onPlayerCanplaythrough($event)"
    @statechanged="playerStateChanged($event)"
    @ready="playerReadied" 
    -->
  </div>
</template>

<script>
import "video.js/dist/video-js.css";
// import video_zhCN from "video.js/dist/lang/zh-CN";
import { videoPlayer } from "vue-video-player";
import videojs from "video.js";
import "videojs-contrib-hls";
import { Notification } from "element-ui";
// videojs.addLanguage("zh-CN", video_zhCN)
export default {
  data() {
    return {
      videotype: "application/x-mpegURL",
      src: null,
      // 很多参数其实没必要的，也还有很多参数没列出来，只是把我看到的所有文章做一个统计
      posterSrc: "../../public/img/loading.gif",
      videoSrc: "http://ivi.bupt.edu.cn/hls/cctv1hd.m3u8",

      // videoSrc: "",
      // playerOptions: {
      //   autoplay: false, //如果true,浏览器准备好时开始回放。(好像微信浏览器不太行)
      //   techOrder: ["html5"], // 需要加载的插件，如果是要兼容flash的话，必须先加载flash。顺序不能错：['flash','html5']
      //   flash: {
      //     hls: { withCredentials: false },
      //   },
      //   html5: { hls: { withCredentials: false } },
      //   muted: true, // 默认情况下将会消除任何音频。
      //   loop: false, // 导致视频一结束就重新开始。
      //   language: "zh-CN", // 提示的语言 中文的话是 zh-CN
      //   fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
      //   preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
      //   aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
      //   // playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度 0.7倍...
      //   sources: [
      //     // 播放的资源列表，虽然不知为何是个数组，可能是，轮流播放？
      //     {
      //       withCredentials: false, // 直播是否有播放令牌，反正我是没有
      //       type: "application/x-mpegURL", // m3u8/Hls 的视频流类型
      //       // src: "http://ivi.bupt.edu.cn/hls/cctv6hd.m3u8", // 对应播放的m3u8 路径
      //       src: "http://ivi.bupt.edu.cn/hls/cctv6hd.m3u8", // 对应播放的m3u8 路径
      //     },
      //   ],
      //   poster: "../../public/img/loading.gif", //你的封面地址
      //   notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
      //   controls: true, // 是否显示操作条
      //   controlBar: {
      //     // 播放的操作
      //     timeDivider: true, // 时间分割线
      //     durationDisplay: true, // 总时间
      //     remainingTimeDisplay: false,
      //     fullscreenToggle: true, //全屏按钮
      //   },
      // },
    };
  },
  created() {},
  mounted() {
    // console.log("this is current player instance object", this.player);
    // var that = this;
    // var player = videojs(
    //   "videobox",
    //   {
    //     bigPlayButton: true,
    //     textTrackDisplay: true,
    //     posterImage: true,
    //     errorDisplay: false,
    //     controlBar: false,
    //     playbackRates: [0.5, 1, 1.5, 2],
    //     preload: "none",
    //     ControlBar: {
    //       // 自定义按钮的位置
    //       children: [
    //         {
    //           name: "playToggle",
    //         },
    //         {
    //           name: "progressControl",
    //         },
    //         {
    //           name: "currentTimeDisplay",
    //         },
    //         {
    //           name: "timeDivider",
    //         },
    //         {
    //           name: "durationDisplay",
    //         },
    //         {
    //           name: "pictureInPictureToggle", //画中画播放模式
    //         },
    //         {
    //           name: "fullscreenToggle",
    //           position: "right",
    //         },
    //       ],
    //     },
    //   },
    //   function onPlayerReady() {
    //     // this.play();
    //     setTimeout(() => {
    //       //延时确保能监听到视频源错误
    //       var mediaError = this.error();
    //       if (mediaError != null && mediaError.code) {
    //         // _this.isError = true;
    //         // Dialog.alert({
    //         //   message:
    //         //     "啊哦，播放出错了。<br>请刷新重试，如无法播放建议您观看其它内容。",
    //         //   confirmButtonText: "确定",
    //         // }).then(() => {
    //         //   _this.goback();
    //         // });
    //         console.log(mediaError);
    //         console.log("这是啊啊啊啊啊啊");
    //         that.onPlayerPlay();
    //       }
    //     }, 1000);
    //   }
    // );
    // player.width(this.videoW)   //设置播放器宽度
  },
  beforeDestroy() {
    const videoDom = this.$refs.videoRef; //不能用document 获取节点
    videojs(videoDom).dispose(); //销毁video实例，避免出现节点不存在 但是flash一直在执行,也避免重新进入页面video未重新声明
  },
  computed: {
    // player() {
    //   return this.$refs.videoPlayer.player;
    // },
  },
  methods: {
    init() {
      console.log(this.videoSrc, "播放地址");

      var player = videojs(
        "videobox",
        {
          autoplay: false,
          bigPlayButton: true,
          textTrackDisplay: true,
          posterImage: true,
          errorDisplay: false,
          controlBar: false,
          playbackRates: [0.5, 1, 1.5, 2],
          preload: "none",
          ControlBar: {
            // 自定义按钮的位置
            children: [
              {
                name: "playToggle",
              },
              {
                name: "progressControl",
              },
              {
                name: "currentTimeDisplay",
              },
              {
                name: "durationDisplay",
              },
              {
                name: "QualitySelectorBar",
              },
              {
                name: "pictureInPictureToggle", //画中画播放模式
              },
              {
                name: "fullscreenToggle",
                position: "right",
              },
            ],
          },
        },
        function () {
          console.log("准备", this);
          // this.on("PlayerReady", (e) => {
          //   console.log("准备",e)
          // });
          this.on("error", (e) => {
            //视频错误监听事件
            console.log("视频错误", e);
          });
          this.on("loadstart", () => {
            //视频开始加载数据监听事件
          });
          this.on("canplaythrough", (e) => {
            //视频可以播放监听事件
            console.log("可以播放", e);
          });
          this.on("playing", () => {
            //  视频正在播放监听事件
          });
          this.on("stalled", () => {
            console.log("网络异常");
          });
          this.on("waiting", (e) => {
            //视频正在加载事件
            console.log("正在加载", e);
          });

          // this.play();
          setTimeout(() => {
            //延时确保能监听到视频源错误
            var mediaError = this.error();
            if (mediaError != null && mediaError.code) {
              // _this.isError = true;
              // Dialog.alert({
              //   message:
              //     "啊哦，播放出错了。<br>请刷新重试，如无法播放建议您观看其它内容。",
              //   confirmButtonText: "确定",
              // }).then(() => {
              //   _this.goback();
              // });
              Notification({
                title: "提示",
                message: `视频播放出错:${mediaError.type}!`,
                type: "warning",
                position: "top-left",
              });
              // console.log(mediaError,'视频错误信息');

              // that.onPlayerPlay();
            }
          }, 1000);
        }
      );
      this.$nextTick(function () {
        // const controlBar = player.getChild("ControlBar") || null;
        // const qualityButton = new QualityMenuButton(player, {
        //   qualitys: "vm.qualitys", //传入视频的画质信息
        //   defaultQuality: "vm.qualitys[0]", //传入视频的默认画质
        // });
        // if (controlBar) {
        //   controlBar.addChild(
        //     qualityButton,
        //     {},
        //     controlBar.children().length - 2
        //   );
        // }
      });
    },
    // listen event
    play(e) {
      console.log(e, "播放地址");
      this.videoSrc = e;
      // const videoDom = this.$refs.videoRef; //不能用document 获取节点

      // videojs(videoDom).src(e);

      // console.log(videojs(videoDom).src(e));
      console.log("这里播放源");
    },
    onPlayerPlay(player) {
      console.log("这是播放事件");
      console.log("player play!", player);
    },
    onPlayerPause(player) {
      // console.log('player pause!', player)
    },
    // ...player event

    // or listen state event
    playerStateChanged(playerCurrentState) {
      // console.log('player current update state', playerCurrentState)
    },

    // player is ready
    playerReadied(player) {
      console.log("the player is readied", player);
      // you can use it to do something...
      // player.[methods]
    },
  },
};
</script>
<style  scoped>
.video-js {
  /* 给.video-js设置字体大小以统一各浏览器样式表现，因为video.js采用的是em单位 */
  font-size: 14px;
}
.video-js button {
  outline: none;
}
.video-js.vjs-fluid,
.video-js.vjs-16-9,
.video-js.vjs-4-3 {
  /* 视频占满容器高度 */
  height: 100%;
  background-color: #161616;
}
.vjs-poster {
  background-color: #161616;
}
.video-js .vjs-big-play-button {
  z-index: 0;
  /* 中间大的播放按钮 */
  display: none !important;
  font-size: 2.5em;
  line-height: 2.3em;
  height: 2.5em;
  width: 2.5em;
  -webkit-border-radius: 2.5em;
  -moz-border-radius: 2.5em;
  border-radius: 2.5em;
  background-color: rgba(115, 133, 159, 0.5);
  border-width: 0.12em;
  margin-top: -1.25em;
  margin-left: -1.75em;
}

.video-js.vjs-error .vjs-big-play-button {
  /* 视频加载出错时隐藏播放按钮 */
  display: none !important;
}
.vjs-loading-spinner {
  /* 加载圆圈 */
  font-size: 2.5em;
  width: 2em;
  height: 2em;
  border-radius: 1em;
  margin-top: -1em;
  margin-left: -1.5em;
}
.video-js .vjs-control-bar {
  /* 控制条默认显示 */
  display: flex;
}
.video-js .vjs-time-control {
  display: block;
}

.vjs-button > .vjs-icon-placeholder:before {
  /* 控制条所有图标，图标字体大小最好使用px单位，如果使用em，各浏览器表现可能会不大一样 */
  display: none !important;
  font-size: 22px;
  line-height: 1.9;
}
.video-js .vjs-playback-rate .vjs-playback-rate-value {
  line-height: 2.4;
  font-size: 18px;
}
</style>